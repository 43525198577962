<template>
  <body>
    <div class="banner-bg">
      <div class="banner-content">
        <v-container>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-img max-height="240" contain class="mgt-12px"
              :src="require('@/assets/image/product-1.jpg')"
            />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <div class="white-text heading mgt-12px">
                {{ title }} <br/>
                <div><span>({{ date }})</span></div>
                <br/>
                <div>
                  <span>
                    มี Certificate จากอาจารย์จุฬาลงกรณ์มหาวิทยาลัยให้ทั้งสองหลักสูตร
                  </span>
                </div>
                <br/>
                {{ price }}
                <!-- <br>
                <br>
                <div class="text-xs-right caption">
                  จากราคาเต็ม {{ price }}
                </div> -->
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <v-app class="ct-mw">
      <v-container>
        <v-row>
          <!-- Mobile -->
          <v-col cols="12">
            <v-row justify="center">
              <router-link
                v-if="isSale"
                :to="`/payment/cart/${sku}`"
                class="button hidden-md-and-up ltsp">
                <v-btn color="error"><strong> BUY NOW </strong></v-btn>
              </router-link>
              <v-btn
                v-else
                class="button hidden-md-and-up ltsp"
                disabled
              >
                <strong> SOLD OUT </strong>
              </v-btn>
            </v-row>

          </v-col>
          <v-col cols="12" sm="12" md="4" class="hidden-md-and-up">
            <v-container class="white-bg shadow mt-24px" >
              <br/>
              <v-row dense no-gutters
                v-for="salebox in salebox"
                :key="salebox.id">
                <v-col cols="2">
                  <v-row justify="center">
                    <v-img
                      :src="`${salebox.imgicon}`"
                      max-width="30px">
                    </v-img>
                  </v-row>
                </v-col>
                <v-col v-if="'link' in salebox" class="text-content">
                  <a :href="`${salebox.link}`">
                    {{ salebox.title }}</a>
                </v-col>
                <v-col v-else class="text-content">
                  {{ salebox.title }}
                </v-col>
              </v-row>
              <br/>
              <!-- <v-row dense no-gutters>
                <v-col cols="2">
                  <br/>
                  <v-row justify="center">
                    <v-img
                      :src="require('@/assets/image/icon-14.svg')"
                      max-width="30px">
                    </v-img>
                  </v-row>
                    <v-icon>fas fa-chalkboard-teacher</v-icon>
                </v-col>
                <v-col >
                  <strong>สอนโดย</strong><br/>
                  ผศ.ดร.ฑิตยา หวานวารี<br/>
                  ภาควิชาคณิตศาสตร์และวิทยาการคอมพิวเตอร์ คณะวิทยาศาสตร์ <br/>
                  จุฬาลงกรณ์มหาวิทยาลัย <br/><br/>
                </v-col>
              </v-row> -->
            </v-container>
          </v-col>
          <!-- About Product  -->
          <v-col cols="12" md="8">
            <v-content class="white-bg mt-24px">
              <div class="pd-24px text-content">
                หากคุณเป็นหนึ่งคนที่ต้องการสร้างมูลค่าด้วยการสื่อสารผ่านการพูด
                ร่วมกับการค้นหาตัวเองและผู้อื่นเพื่อช่วยให้การสื่อสารของคุณมีประสิทธิภาพ
                คุณกำลังมองหา Package นี้อยู่แน่นอน<br/><br/>

                มี Certificate จากอาจารย์ จุฬาลงกรณ์มหาวิทยาลัย ให้ทั้งสองหลักสูตร<br/><br/>

                <strong>1. Workshop "Storytelling" เล่าเรื่องยากให้เข้าใจง่าย รุ่นที่ 3</strong>
                <br/>
                สอนโดย อ.ดร.ประภัสสร จันทร์สถิตย์พร จากคณะนิเทศศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย<br/>
                เวิร์กชอปเชิงปฏิบัติการที่ช่วยให้คุณเสริมความมั่นใจ
                Upskill การเล่าเรื่องและวิเคราะห์ผู้ฟัง
                อีกทั้งยังได้รับมิตรภาพดี ๆ จากหลากหลายอาชีพที่จะมาแชร์ประสบการณ์
                และแลกเปลี่ยนเทคนิคการนำเสนอจากการทำงานจริง
                เวิร์กชอปจะจัดขึ้นในวันเสาร์ที่ 14 ธันวาคม พ.ศ. 2562
                <br/><br/>

                <v-responsive :aspect-ratio="16/9" class="mb-4">
                  <iframe
                    src="https://www.youtube.com/embed/AqgRjoXqgww"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allow="fullscreen; encrypted-media; gyroscope; picture-in-picture;"
                    allowFullScreen
                  >
                  </iframe>
                </v-responsive>

                <strong>Workshop นี้เหมาะกับใคร</strong><br/>
                📌ผู้ที่ต้องการเสริมสร้างบุคลิกและความมั่นใจให้กับตนเอง<br/>
                📌ผู้ที่ต้องการเสริมสร้างทักษะด้านการสื่อสาร ในรูปแบบที่หลากหลาย
                โดยเริ่มจากพื้นฐานด้านการออกเสียงอย่างถูกต้อง รวมถึงการใช้ท่าทาง
                ประกอบการเสนอเพื่อเพิ่มพูนศักยภาพด้านการสื่อสารในที่สาธารณะได้อย่างมีประสิทธิภาพ
                (Public Speaking)<br/>
                📌ผู้ที่ต้องการ Refresh เทคนิคการสื่อสาร เพื่อนำไปใช้ในการทำงาน
                เช่น การใช้ Presentation ประกอบการนำเสนอ และการพูดเพื่อโน้มน้าวใจคนฟัง<br/><br/>


                <strong>สิ่งที่ผู้เรียนจะได้เรียนรู้ใน Workshop นี้</strong><br/>
                📌เสริมความมั่นใจ Upskill การเล่าเรื่องและการวิเคราะห์ผู้ฟัง<br/>
                📌การเรียงลำดับความคิดของการเล่าเรื่องเพื่อถ่ายทอด Key Message ให้โดนใจผู้ฟัง
                ผ่านสื่อการนำเสนอที่เหมาะสม<br/>
                📌มิตรภาพดี ๆ จากหลากหลายอาชีพที่จะมาแชร์ประสบการณ์
                และแลกเปลี่ยนเทคนิคการนำเสนอจากการทำงานจริง<br/>
                📌ประสบการณ์การนำเสนอ และ โค้ชชิ่ง โดยหนึ่งในกูรูด้านการเล่าเรื่องระดับประเทศ<br/>
                📌ผู้เข้าร่วมกิจกรรมจะได้นำเสนอ รวมถึงได้รับไฟล์วิดีโอนำเสนอของตัวเอง (Soft File) และ
                ได้รับ Feedback จากอาจารย์ประจำชุดวิชาอย่างใกล้ชิด และ ทั่วถึง<br/><br/>

                <strong>2. Persuasive Presentation and Negotiation Pathway</strong><br/>
                สอนโดยคณาจารย์จากคณะนิเทศศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย<br/>
                หลักสูตรออนไลน์ 3 เดือน ที่จะพาคุณไปเปิดโลกแห่งการสื่อสาร
                อันเป็นทักษะที่สำคัญในศตวรรษที่ 21
                คุณจะได้เรียนรู้เกี่ยวกับพื้นฐานของการสื่อสาร การออกแบบสารเพื่อการเล่าเรื่อง
                การผลิตสื่อประกอบการนำเสนอและสื่อสำหรับประชาสัมพันธ์ในรูปแบบต่าง ๆ
                การนำเสนอต่อหน้าสาธารณะ และการเจรจาต่อรอง เพื่อให้คุณสามารถสื่อสารความคิดของคุณ
                และนำเสนอประเด็นต่าง ๆ ได้อย่างมีประสิทธิภาพ
                เพื่อสร้างมูลค่าทางธุรกิจและพัฒนาตนเองให้ทันยุคทันกระแสในปัจจุบัน
                ใน Pathway จะมีทั้งเนื้อหาและการฝึกปฏิบัติเพื่อให้เรียนรู้จากการลงมือทำ<br/><br/>

                <v-responsive :aspect-ratio="16/9" class="mb-4">
                  <iframe
                    src="https://www.youtube.com/embed/9svl868orZY"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allow="fullscreen; encrypted-media; gyroscope; picture-in-picture;"
                    allowFullScreen
                  >
                  </iframe>
                </v-responsive>

                ⏱เวลาเรียนทั้งหมด 30 ชั่วโมง<br/><br/>

                ประกอบด้วย 4 รายวิชา<br/>
                14 บทใหญ่ 59 คลิปย่อย 7 กิจกรรมเสริมบทเรียน <br/>
                วิดีโอสั้น กระชับ ตรงประเด็น พร้อมวิดีโอ Scenario สุดล้ำไม่เหมือนใคร<br/><br/>

                <strong>Pathway นี้เหมาะกับใคร</strong><br/>
                ผู้ที่ต้องการเสริมสร้างทักษะด้านการสื่อสารในรูปแบบที่หลากหลาย
                โดยเริ่มจากพื้นฐานด้านการออกเสียงอย่างถูกต้อง
                รวมถึงการใช้ท่าทางประกอบการเสนอเพื่อเพิ่มพูนศักยภาพด้านการสื่อสารในที่สาธารณะ
                (Public Speaking) ได้อย่างมีประสิทธิภาพ<br/><br/>

                <strong>สิ่งที่ผู้เรียนจะได้เรียนรู้ใน Pathway นี้</strong><br/>
                📌เรียนรู้การสื่อสารของมนุษย์ในเชิงธุรกิจ<br/>
                📌เรียนรู้การเล่าเรื่อง การจัดทำโครงสร้าง องค์ประกอบ ขั้นตอน<br/>
                📌เทคนิคการอุปมาและการเปรียบเทียบ ตลอดจนการออกแบบสื่อ<br/>
                📌เรียนรู้กลยุทธ์ที่ใช้ในการเจรจาต่อรอง ประเภทของการเจรจาต่อรอง
                หลักการจัดการความตึงเครียดระหว่างการเจรจาต่อรอง<br/><br/>

                คอร์สออนไลน์ที่มีผู้เรียนมากกว่า 1,800 คนภายใน 4 เดือน<br/><br/>

                สอบถามข้อมูลเพิ่มเติม<br/>
                LINE Official Account: <a href="https://line.me/R/ti/p/@achieveplus" target="_blank">@achieveplus</a><br/>
                Facebook: <a href="https://www.facebook.com/Achieve.plus.th" target="_blank">CHULA MOOC Achieve</a><br/><br/>
              </div>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/product-1-01.jpg')"
                  ></v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/product-1-04.jpg')">
                  <div class="fill-height bottom-gradient"></div>
                  </v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/product-1-02.jpg')">
                  <div class="fill-height bottom-gradient"></div>
                  </v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/product-1-03.jpg')">
                  <div class="fill-height bottom-gradient"></div>
                  </v-img>
                </v-col>
              </v-row>
            </v-content>
          </v-col>
          <!-- Desktop -->
          <v-col cols="12" md="4" class="hidden-sm-and-down">
            <v-content class="white-bg shadow mt-24px">
              <v-col cols="12">
                <v-row justify="center">
                  <router-link
                    v-if="isSale"
                    :to="`/payment/cart/${sku}`"
                    class="button ltsp">
                    <v-btn color="error"><strong> BUY NOW </strong></v-btn>
                  </router-link>
                  <v-btn
                    v-else
                    class="button ltsp"
                    disabled
                  >
                    <strong> SOLD OUT </strong>
                  </v-btn>
                </v-row>
              </v-col>
              <v-container>
                <br/>
                <v-row dense no-gutters
                  v-for="salebox in salebox"
                  :key="salebox.id">
                  <v-col cols="2">
                    <v-row justify="center">
                      <v-img
                        :src="`${salebox.imgicon}`"
                        max-width="30px">
                      </v-img>
                    </v-row>
                  </v-col>
                  <v-col v-if="'link' in salebox" class="text-content">
                    <a :href="`${salebox.link}`">
                      {{ salebox.title }}</a>
                  </v-col>
                  <v-col v-else-if="salebox.blank" cols="11"><br/></v-col>
                  <v-col v-else class="text-content">
                    {{ salebox.title }}
                  </v-col>
                </v-row>
              </v-container>

            </v-content>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </body>
</template>

<script>
export default {
  name: 'workshopDetail',
  props: ['workshopId'],
  data() {
    return {
      card_text: '',
      sku: 'wspw0130020008',
      workshop: [
        { title: 'Bundle Persuasive Pathway + Storytelling Workshop ครั้งที่ 3', date: '24/11/2562' },
      ],
      title: 'Bundle Persuasive Pathway + Storytelling Workshop ครั้งที่ 3',
      date: '24/11/2562',
      price: '8,550 บาท',
      salebox: [
        {
          title: 'อบรมเชิงปฏิบัติการ (workshop)', icon: 'fas fa-laptop-code', imgicon: require('@/assets/image/icon-06.svg'), id: 1,
        },
        {
          title: 'วันเสาร์ที่ 14 ธันวาคม 2562', icon: 'fas fa-calendar-alt', imgicon: require('@/assets/image/icon-09.svg'), id: 2,
        },
        {
          title: '08:00 - 17:30 น.', icon: 'far fa-clock', imgicon: require('@/assets/image/icon-10.svg'), id: 3,
        },
        {
          title: 'หอประชุมนิเทศศาสตร์ ชั้น 11 อาคารมงกุฎสมมติวงศ์', icon: 'fas fa-map-marker-alt', imgicon: require('@/assets/image/icon-11.svg'), id: 4,
        },
        {
          title: 'ระดับพื้นฐาน (Fundamental level)', icon: 'fas fa-tasks', imgicon: require('@/assets/image/icon-12.svg'), id: 5,
        },
        {
          blank: true,
        },
        {
          title: 'ชุดวิชา (pathway)', icon: 'fas fa-laptop-code', imgicon: require('@/assets/image/icon-06.svg'), id: 6,
        },
        {
          title: 'แจ้งวันเริ่มเรียนกับทางทีมงาน', icon: 'fas fa-calendar-alt', imgicon: require('@/assets/image/icon-09.svg'), id: 7,
        },
        {
          title: '3 เดือน', icon: 'far fa-clock', imgicon: require('@/assets/image/icon-10.svg'), id: 8,
        },
        {
          title: 'เรียนออนไลน์', icon: 'fas fa-map-marker-alt', imgicon: require('@/assets/image/icon-11.svg'), id: 9,
        },
        {
          title: 'ระดับพื้นฐาน (Fundamental level)', icon: 'fas fa-tasks', imgicon: require('@/assets/image/icon-12.svg'), id: 10,
        },

      ],
      isSale: new Date('2019-12-12T05:00:00.000Z') > new Date(),
    };
  },
};
</script>
<style scoped>
.ct-mw{
  max-width: 1100px;
  margin: auto;
}
.banner-bg{
  background-color: #001544;
  /* margin-left: -600px;
  margin-right: -600px; */
}
.banner-content{
  max-width: 1100px;
  margin: auto;
}
.content{
  height: 1500px;
  margin: auto;
  max-width: 1200px;
}
.heading{
  font-size: 1.3125rem;
  font-weight: 600;
  letter-spacing: 2px;
}
.pd-24px{
  margin-left: 31px;
  margin-right: 32px;
}
.pd-12px{
  padding-left: 12px;
}
.pdr-16px{
  padding-left: 24px;
  padding-right: 16px;
}
.white-bg{
  box-shadow: #001544;
  border: #001544;
}
.shadow{
  box-shadow: 2px 2px 10px #dfdfdf;
  border-color: #001544;
}
.mt-24px{
  margin-top: 24px;
}
.mlr-24px{
  margin-left: 24px;
  margin-right: 24px;
}
.v-icon{
  font-size: 16px;
}
.mgt-12px{
  margin-top: 12px;
}
.v-btn__content{
  font-weight: 700;
}
.ltsp{
  font-weight: 700;
  letter-spacing: 2px;
}
.mg{
  margin-bottom: 16px;
}
.text-content{
  font-size: 0.875rem;
}
@media screen and (max-width: 770px){
  .heading{
    font-size: 1.0rem;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 1.4rem;
  }
}
</style>
